/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

/* ========================================================================
 * Push Menu Function - brings menu in from the right on mobile to tablet
 * ======================================================================== */

/*!
 * jPushMenu.js
 * 1.1.1
 * @author: takien
 * http://takien.com
 * Original version (pure JS) is created by Mary Lou http://tympanus.net/
 * Mobile Push Menu, mobile style only - https://tympanus.net/codrops/2013/04/17/slide-and-push-menus/
 */

jQuery(function ($) {

    $.fn.jPushMenu = function (customOptions) {
        var o = $.extend({}, $.fn.jPushMenu.defaultOptions, customOptions);

        /* add class to the body.*/

        $('body').addClass(o.bodyClass);
        $(this).addClass('jPushMenuBtn');
        $(this).click(function () {
            var target = '',
                push_direction = '';


            if ($(this).is('.' + o.showLeftClass)) {
                target = '.cbp-spmenu-left';
                push_direction = 'toright';
            }
            else if ($(this).is('.' + o.showRightClass)) {
                target = '.cbp-spmenu-right';
                push_direction = 'toleft';
            }
            else if ($(this).is('.' + o.showTopClass)) {
                target = '.cbp-spmenu-top';
            }
            else if ($(this).is('.' + o.showBottomClass)) {
                target = '.cbp-spmenu-bottom';
            }


            $(this).toggleClass(o.activeClass);
            $(target).toggleClass(o.menuOpenClass);

            if ($(this).is('.' + o.pushBodyClass)) {
                $('body').toggleClass('cbp-spmenu-push-' + push_direction);
            }

            /* disable all other button*/
            $('.jPushMenuBtn').not($(this)).toggleClass('disabled');

            return false;
        });
        var jPushMenu = {
            close: function (o) {
                $('.jPushMenuBtn,body,.cbp-spmenu').removeClass('disabled active cbp-spmenu-open cbp-spmenu-push-toleft cbp-spmenu-push-toright');
            }
        };

        if (o.closeOnClickOutside) {
            $(document).click(function () {
                jPushMenu.close();
            });

            $('.cbp-spmenu,.toggle-menu').click(function (e) {
                e.stopPropagation();
            });
        }
    };

    /* in case you want to customize class name,
    *  do not directly edit here, use function parameter when call jPushMenu.
    */
    $.fn.jPushMenu.defaultOptions = {
        bodyClass: 'cbp-spmenu-push',
        activeClass: 'menu-active',
        showLeftClass: 'menu-left',
        showRightClass: 'menu-right',
        showTopClass: 'menu-top',
        showBottomClass: 'menu-bottom',
        menuOpenClass: 'cbp-spmenu-open',
        pushBodyClass: 'push-body',
        closeOnClickOutside: false
    };

});

jQuery(function ($) {

    $('.toggle-menu').jPushMenu();

});
